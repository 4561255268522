import React from "react";
import { IoMdHome } from "react-icons/io";
import { IoPersonOutline } from "react-icons/io5";
import { IoCode } from "react-icons/io5";
import { MdMonitor } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";

import { Container } from "react-bootstrap";
const SidePanel = () => {
  return (
    <section className="side-panel">
      <div>
        <a href="#">
          <IoMdHome />
        </a>
      </div>
      <div>
        <a href="#about">
          <IoPersonOutline />
        </a>
      </div>
      <div>
        <a href="#skills">
          <IoCode />
        </a>
      </div>
      <div>
        <a href="#works">
          <MdMonitor />
        </a>
      </div>
      {/* <div>
        <a href="#contact">
          <MdOutlineEmail />
        </a>
      </div> */}
    </section>
  );
};
export default SidePanel;
