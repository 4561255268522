import React from "react";

import { IoCodeWorking } from "react-icons/io5";
import {
  FaInstagram,
  FaGithub,
  FaYoutube,
  FaLinkedin,
  FaLinkedinIn,
} from "react-icons/fa";
import { RiDiscordLine } from "react-icons/ri";
import { Container } from "react-bootstrap";

const NavBar = () => {
  return (
    <nav>
      <Container className="space-between py-4">
        <div className="brand d-flex align-items-center">
          <IoCodeWorking className="logo flex-align me-2" />
          <h2>Ahmed.programming</h2>
        </div>
        <ul className="d-flex align-items-center justify-content-end Menu-M">
          <li>
            <a href="/">Home</a>
          </li>
          <li className=" mx-md-3 ml-sm-2">
            <a href="/contact">contact</a>
          </li>
          <div className="social--media mobile-hide space-between Media-M">
            <li className="d-flexend m-0">
              <FaInstagram className="icon" />
              <a
                href="https://www.instagram.com/ahmed.programming"
                target="_blank"
                className="tab-hide"
              >
                Instagram
              </a>
            </li>
            <li className="d-flexend ">
              {/* <RiDiscordLine  /> */}
              <FaLinkedinIn className="icon" />
              <a
                href="https://www.linkedin.com/in/ahmed-dev13/"
                target="_blank"
                className="tab-hide"
              >
                Linkedin
              </a>
            </li>
            <li className="d-flexend">
              <FaGithub className="icon" />
              <a
                href="https://github.com/ahmedaddy"
                target="_blank"
                className="tab-hide"
              >
                Github
              </a>
            </li>
          </div>
        </ul>
      </Container>
    </nav>
  );
};

export default NavBar;
