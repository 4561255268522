import React from "react";
import "./works.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper/modules";

// images ===================================>
import project1 from "../../assets/project1.jpg";
import project2 from "../../assets/project2.jpg";
import project3 from "../../assets/project3.jpg";
// import project5 from "../../assets/project5.webp";

const Works = () => {
  const images = [
    {
      img: project1,
      to: "https://e-commerce-e1v.pages.dev/",
    },
    {
      img: project2,
      to: "https://alcode.ma",
    },
    {
      img: project3,
      to: "https://moviesapp-817.pages.dev/",
    },
    // {
    //   img: project2,
    //   to: "https://ahmedaddy.github.io/first_advanced_project/",
    // },
    // {
    //   img: project3,
    //   to: "https://ahmedaddy.github.io/prsonnelweb_dev/",
    // },
    // {
    //   img: project5,
    //   to: "https://ahmedaddy.github.io/appdownload/",
    // },
    // {
    //   img: project5,
    //   to: "https://e-commerce-e1v.pages.dev/",
    // },
  ];
  // const breakpoints = {
  //   // when window width is >= 320px
  //   767: {
  //     navigation: true,
  //   },
  //   // when window width is >= 480px
  //   300: {
  //     navigation: false,
  //   },
  // };
  return (
    <div className="works" id="works">
      <div className="container">
        <div className="main--title">
          <div style={{ margin: "auto", width: "80%" }}>
            <h1 className="H1-U" data-aos="fade-right" data-aos-duration="2000">
              Works
            </h1>
            <p
              className="Para-M"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <br />
              the most important projects I have worked on. You can see more
              projects on my account on{" "}
              <a
                href="https://github.com/ahmedaddy"
                target="_blank"
                className=" text-primary"
              >
                github
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="slides" data-aos="fade-up" data-aos-duration="2000">
        <Swiper
          // breakpoints={breakpoints}
          spaceBetween={10}
          slidesPerView={1}
          navigation={true}
          modules={[Navigation]}
        >
          {images.map((data) => {
            return (
              <div className="slide">
                <SwiperSlide>
                  <div>
                    <a href={data.to} target="_blank">
                      <img src={data.img} alt="projects" />
                    </a>
                  </div>
                </SwiperSlide>
              </div>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Works;
