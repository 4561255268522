import React, { useEffect } from "react";
import "./skills.css";

import { TfiPaintRoller } from "react-icons/tfi";
import { FaLaptopCode } from "react-icons/fa";

import { RiHtml5Line } from "react-icons/ri";
import { FaReact, FaCss3Alt } from "react-icons/fa6";
import { FaNodeJs } from "react-icons/fa";
import { SiMongodb } from "react-icons/si";
import { SiExpress } from "react-icons/si";
import aos from "aos";
import "aos/dist/aos.css";
const Skills = () => {
  useEffect(() => {
    aos.init({ duration: 2000 });
  });
  return (
    <div className="skills" id="skills">
      <div className="container">
        <div className="main--title">
          <h1 className="H1-U" data-aos="fade-right" data-aos-duration="2000">
            Skills
          </h1>
          <p className="Para-M" data-aos="fade-right" data-aos-duration="2000">
            I am striving to never stop learning and improving
          </p>
        </div>

        <div className="tech--list space-around ">
          <div className="box" data-aos="fade-right" data-aos-duration="2000">
            <TfiPaintRoller />
            <div className="Menu-M">Front End</div>
            <p className="Para-M">HTML-CSS-JS-REACTJS</p>
          </div>
          <div className="box" data-aos="fade-left" data-aos-duration="2000">
            <FaLaptopCode />
            <div className="Menu-M">Back End</div>
            <p className="Para-M">Nodejs-EXPRESS-MONGODB</p>
          </div>
        </div>
        <div className="skills--detail">
          <div className="mongodb" data-aos="fade-up" data-aos-duration="1500">
            <div className="icon">
              <SiMongodb />
            </div>
            <h2 className="H2-M">Mongodb</h2>
          </div>
          <div className="express" data-aos="fade-up" data-aos-duration="2000">
            <div className="icon">
              <SiExpress />
            </div>
            <h2 className="H2-M">Express</h2>
          </div>
          <div className="react" data-aos="fade-up" data-aos-duration="2500">
            <div className="icon">
              <FaReact />
            </div>
            <h2 className="H2-M">REACT</h2>
          </div>
          <div className="nodeJs" data-aos="fade-up" data-aos-duration="3000">
            <div className="icon">
              <FaNodeJs />
            </div>
            <h2 className="H2-M">NodeJs</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
