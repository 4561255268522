import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaGithub, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="py-5">
      <Container>
        <Row className=" flex-wrap">
          <Col lg={4} md={6} sm={12}>
            <div className="copy-right">
              © 2024 Developed By,{" "}
              <a
                href="https://www.instagram.com/ahmed.programming"
                target="_blank"
              >
                @ahmed.programming
              </a>
              {/* © 2024 <a href="">@ahmed_dev13</a>. All rights reserved. */}
            </div>
          </Col>
          <Col lg={4} md={6} sm={12} className="d-flex aligm-items-center ">
            <a
              href="https://www.instagram.com/ahmed.programming"
              target="_blank"
            >
              <FaInstagram />
            </a>
            <a href="https://www.youtube.com/@Ahmed_Addy" target="_blank">
              <FaYoutube />
            </a>
            <a href="https://github.com/ahmedaddy" target="_blank">
              <FaGithub />
            </a>
            <a href="https://www.linkedin.com/in/ahmedaddy" target="_blank">
              <FaLinkedin />
            </a>
          </Col>
          <Col lg={4} md={6} sm={12}>
            Design By <a href="#">JohannLeon</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
