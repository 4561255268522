import React, { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { FiSend } from "react-icons/fi";

const Contact = () => {
  const form = useRef();

  const [done, setDone] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_gyexboi",
        "template_yopwvoi",
        form.current,
        "gTw-7f1S50UtAjkgA"
      )
      .then(
        (result) => {
          form.current.reset();
          setDone(true);
          setTimeout(() => {
            setDone(false);
          }, 5000);
        },
        (error) => {
          setDone(false);
        }
      );
  };
  return (
    <section className="contact " id="contact">
      <Container>
        <div className="main--title">
          <div style={{ margin: "auto", width: "80%" }}>
            <h1 className="H1-U">Contact</h1>
            <p className="Para-M">
              My thoughts on technology and business, Let’s connect to discuss
              your project and make it a reality!
            </p>
          </div>
        </div>
        <div className=" w-fit m-auto fit-content">
          <button className="Logo-M">Send me a message</button>
        </div>
        <form
          ref={form}
          onSubmit={sendEmail}
          className="form w-50 m-auto w-sm-100"
        >
          <Row className="d-flex align-items-center my-3">
            <Col md={6} sm={12} className="d-flex flex-column mb-3">
              <label className="mb-2">Your name *</label>
              <input
                type="text"
                placeholder="Enter your name"
                name="name"
                id=""
                required
              />
            </Col>
            <Col md={6} sm={12} className="d-flex flex-column mb-3">
              <label className="mb-2">Your email *</label>
              <input
                type="email"
                placeholder="Enter your email"
                name="email"
                id=""
                required
              />
            </Col>
          </Row>
          <div className=" my-3">
            <label className="mb-2">Your message *</label>
            <input
              type="text"
              placeholder="Enter your needs"
              name="message"
              id=""
              required
            />
          </div>
          <div className="fit-content m-auto">
            <button className="form-button">
              <div className="d-flex align-items-center">
                Send Message
                <FiSend />
              </div>
            </button>
          </div>
        </form>
      </Container>
    </section>
  );
};

export default Contact;
