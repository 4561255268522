import React, { useEffect } from "react";
import { FaRegEnvelope } from "react-icons/fa";
import { FaLink } from "react-icons/fa6";
import { MdOutlinePlace, MdWorkOutline } from "react-icons/md";
import profile from "../../assets/profile.png";
import resume from "../../assets/Ahmed-Addy-Resume(CV).pdf";

import { Container, Row, Col, Spinner } from "react-bootstrap";

// import bgVideo from "../../assets/hero-bg.mp4";
import bgVideo from "../../assets/bg.mp4";
import aos from "aos";
import "aos/dist/aos.css";
import "./Hero.css";

const Hero = () => {
  useEffect(() => {
    aos.init({ duration: 2000 });
  });
  {
    /* <div className="video-background">
    <video style={{ opacity: ".5" }} autoPlay muted loop id="myVideo">
      <source src={bgVideo} type="video/mp4" />
    </video>
  </div> */
  }
  return (
    <>
      <section className="hero" id="hero">
        <Container>
          <h1 className="title" data-aos="fade-up" data-aos-duration="2000">
            developer
          </h1>

          <Row className="holder align-items-center">
            <Col
              lg={4}
              md={12}
              data-aos="fade-right"
              data-aos-duration="2500"
              style={{ width: "fit-content", margin: "auto" }}
            >
              <div className="card">
                <img src={profile} alt="profile" />
                <div className="personal--info">
                  <h3 className="name">Ahmed</h3>
                  <div className="work">full-stack developer</div>
                </div>
                <div className="info">
                  <div className="gmail flex-align">
                    <FaRegEnvelope />
                    <a href="mailto:ahmeddev.bz@gmail.com">
                      ahmeddev.bz@gmail.com
                    </a>
                  </div>
                  <div className="place flex-align">
                    <MdOutlinePlace />
                    Morocco
                  </div>
                  <div className="work flex-align">
                    <MdWorkOutline />
                    Open to get hired
                  </div>
                  <div className="web-site flex-align">
                    <FaLink />
                    www.ahmedaddy.pages.dev
                  </div>
                </div>
                <div className="skills flex-align">
                  <div className="javascript">javascript</div>
                  <div className="react">react</div>
                  <div className="nodejs-express">nodejs/express</div>
                  <div className="mongodb">mongodb</div>
                  <div className="git-github">git/github</div>
                </div>
                <a href={resume} download className="button CV">
                  Download Resume{"(CV)"}
                </a>
              </div>
            </Col>
            <Col lg={6} md={8} sm={12} xs={12}>
              <div className="info">
                <div className="content">
                  <div
                    className="tags"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                  >
                    &lt;h1&gt;
                  </div>
                  <div data-aos="fade-right" data-aos-duration="2000">
                    <div>hey</div>
                    <br />
                    <div>
                      I'am <span>Ahmed</span>
                    </div>
                    <br />
                    <h1 className="H1-U">Full-Stack Developer </h1>
                  </div>
                  <div
                    className="tags"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                  >
                    &lt;/h1&gt;
                  </div>
                  <br />
                  <div data-aos="fade-right" data-aos-duration="2500">
                    <div className="tags">&lt;p&gt;</div>
                    <p>
                      I help business grow by crafting amazing web experiences.
                      If you’re looking for a developer that likes to get stuff
                      done,
                    </p>
                    <div className="tags">&lt;/p&gt;</div>
                  </div>
                  <div className="lets-talk d-flex aling-items-center">
                    <a
                      href="mailto:ahmeddev.bz@gmail.com"
                      style={{ cursor: "pointer" }}
                      className="lets-talk m-0"
                    >
                      Let's Talk
                    </a>
                    <FaRegEnvelope />
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg={2}
              md={4}
              sm={12}
              xs={12}
              className="p-0"
              data-aos="fade-left"
              data-aos-duration="2500"
            >
              <div className="stats">
                <div className="stat Para-M flex-align">
                  3 <div>Programming Language</div>
                </div>
                <div className="stat Para-M flex-align">
                  7 <div>Development Tools</div>
                </div>
                <div className="stat Para-M flex-align">
                  3 <div>Years Of Experience</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Hero;
