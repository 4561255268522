import React from "react";
import Contact from "../componants/contact/contact";

const Contactpage = () => {
  return (
    <>
      <Contact />
    </>
  );
};

export default Contactpage;
