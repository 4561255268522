import React, { useEffect } from "react";
import "./about.css";

import about from "../../assets/about.png";
import aos from "aos";
import "aos/dist/aos.css";
const About = () => {
  useEffect(() => {
    aos.init({ duration: 2000 });
  });
  return (
    <div className="about" id="about">
      <div className="container">
        <div className="card" data-aos="fade-right" data-aos-duration="2000">
          <h1>About Me</h1>
          <p className="Para-M">
            <div className="tags">&lt;p&gt;</div>
            <div>Hello!</div>
            My name is Ahmed and I specialize in web developement that utilizes
            <span>React</span> ,<span>Express</span> ,<span>NodeJS</span> and
            <span>MongoDB</span> etc.
            <br />
            <br />
            I am a highly motivated individual and eternal optimist dedicated to
            writing clear, concise, robust code that works. Striving to never
            stop learning and improving.
            <br />
            <br />
            When I'm not coding, I am
            <span> writing bolgs</span>, reading, or picking up some new
            hands-on art project like <span>photography</span>or making videos
            and sharing my experience.
            <br />
            <br />I like to have my perspective and belief systems challenged so
            that I see the world through new eyes.
            <div className="tags">&lt;/p&gt;</div>
          </p>
        </div>
        <div className="image" data-aos="fade-left" data-aos-duration="2000">
          <img src={about} alt="" />
        </div>
      </div>
    </div>
  );
};

export default About;
