import React from "react";
import Hero from "../componants/hero/hero";
import About from "../componants/aboutMe/about";
import Skills from "../componants/skills/skills";
import Works from "../componants/works/works";
import Blogs from "../componants/blogs/blogs";
import SidePanel from "../componants/sidePanel/sidePanel";
import Contact from "../componants/contact/contact";

const Homepage = () => {
  return (
    <>
      <SidePanel />
      <Hero />
      <About />
      <Skills />
      <Works />
      {/* <Contact /> */}
      {/* <Blogs /> */}
    </>
  );
};

export default Homepage;
